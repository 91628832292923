// extracted by mini-css-extract-plugin
export var ArtistDescription = "Sharp-module--ArtistDescription--rXl6D";
export var ArtistInfos = "Sharp-module--ArtistInfos--BN3sr";
export var ButtonWrapper = "Sharp-module--ButtonWrapper --XiTnv";
export var CardWrapper = "Sharp-module--CardWrapper--kXswB";
export var CarrouselWrapper2 = "Sharp-module--CarrouselWrapper2--SIC8L";
export var Citations = "Sharp-module--Citations--IgIEq";
export var DescriptionWrapper = "Sharp-module--DescriptionWrapper--AnGUB";
export var ImageWrapper = "Sharp-module--ImageWrapper--ijmQb";
export var LinkWrapper = "Sharp-module--LinkWrapper--qTZLU";
export var MobileProtrait = "Sharp-module--MobileProtrait--fRwqG";
export var More = "Sharp-module--More--3Cj7K";
export var MoreButton = "Sharp-module--MoreButton--miqoI";
export var NameWrapper = "Sharp-module--NameWrapper--4eGqN";
export var PdpWrapper = "Sharp-module--PdpWrapper--3BOaR";
export var PhotosWrapper = "Sharp-module--PhotosWrapper--6QwMU";
export var ProfilWrapper = "Sharp-module--ProfilWrapper--3PRUY";
export var TitleWrapper = "Sharp-module--TitleWrapper--Kx1T8";
export var Wrapper = "Sharp-module--Wrapper--8zA-w";