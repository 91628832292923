import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Sharp.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import SharpPhoto1 from "../../../../res/Photos site/Sharp/sharp.jpeg"
import SharpPhoto2 from "../../../../res/Photos site/Sharp/Sharp (4).jpg"
import SharpPhoto3 from "../../../../res/Photos site/Sharp/Sharp (3).jpg"
import SharpPhoto4 from "../../../../res/Photos site/Sharp/Sharp .webp"
import SharpVid from "../../../../res/Photos site/Sharp/sharp.mp4"
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/sharp/past-present-1.jpg"
import PastPresent2 from "../../../../res/sharp/past-present-2.jpeg"
import PastPresent3 from "../../../../res/sharp/past-present-3.jpg"
import Pdp from "../../../../res/sharp/portrait.png"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Sharp",
  name: "Sharp",
  description:"Sharp est à l’origine, avec DELTA, du groupe des KA : les \"Kings Arrive\", au début des années 1980. Et c’est sur la ligne 6 du métro new-yorkais que celui-ci a été le plus actif. Sharp, d'origine caribéenne, a reçu une éducation juive qu’il revendique comme influence majeure dans sa peinture. Dans la tradition juive, il n’existe pas de représentations figuratives, et chaque lettre de l’alphabet sacré hébraïque est porteuse de sens. Ce qui nous fait d’ailleurs aussi penser à la théorie des lettres de Rammellzee. Il explique que son penchant pour l’abstraction lui vient de là. Sharp a commencé à pratiquer le graffiti pour se protéger et pouvoir faire abstraction de l’environnement social violent et frustrant dans lequel il a grandi. L’alphabet moderne occidental est sa quête artistique première. Sharp inclut dans ses peintures ses recherches sur les alphabets grecs, hébraïques, égyptiens et romains, dans l’optique de briser les carcans du langage moderne, et les conditionnements qui s’y rapportent. Sharp a par ailleurs souvent été représenté par son ami l’artiste Martin Wong.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Sharp.",
  photos: [
    { src: PastPresent1, name: "Sharp" },
    { src: PastPresent2, name: "Sharp" },
    { src: PastPresent3, name: "Sharp" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};


const Sharp = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>SHARP <h2 style={{paddingLeft: "16px"}}>(Aaron Goodstone - Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1966</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Helenbeck Galerie, Changing the game, Nice (France) 2009</p>
          <p>- Speerstra Gallery, Magical Radical Renegades, Genève (Suisse)</p>
          <p>- Galeria Art-Vall, Decadent aspect of poverty, Andorre</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Avant Garden Gallery Ghetto Bougeois, Milan</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- My Own Gallery, Live Painting, Milan (Italie)</p>
          <p>- Montana Gallery, Henry Chalfant / Sharp, Barcelone (Espagne)</p>
          <p>- Galleria Palazzo,Daze and Sharp, Florence (Italie)</p>
          <p>- Nano Universe, Bronx Style, Tōkyō (Japon)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Speerstra Gallery, Romance of innocence from hence we came, Paris</p>
          <p>- Carhartt Paris, Nutricious, Paris</p>
          <p>- Sharp-Henry Chalfant, The ex-political factor, Amsterdam</p>
          <p>- Mhi, New protocols of the learned elders, London</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Per M Space Gallery, The Debut of Baby got Knots, Tokyo</p>
          <p>- Fashion Institute of Technology, Deans Gallery, The Seven Thunders, New York</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Speerstra Gallery, The Reeducation of Sharpy Butterz, Paris</p>
          <p>- Mi Art, Prosper, Milan</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- Jacs Gallery, Study of Symbolism, Londres 1997</p>
          <p>- Brick Lane Gallery, Roots Remembered, Londres</p>
          <p>- Galerie L ’Aeronef, Symphony to your fears, Lille 1996</p>
          <p>- Oxford Gallery, Projecting the Pain, Oxford</p>
          <p>- Ark Gallery, Sharp-Jonone, Tokyo</p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- Space-Time-Light Gallery, Between Passion there is always Pain, New York</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Espace Mirajes, Post Modern Supermarket of Style, Paris</p>
          <p>- Parsec Corporation, Induced Vertigo, Berlin</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- Speerstra Gallery / Michel Gillet, From the Gates of the Ghetto, Paris</p>
          <br />
          <br />
         <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Nos Fantômes V2, GHOST Galerie Paris, octobre-janvier </p>
          <p>- HEY! Le dessin, HALLE SAINT PIERRE 18E, PARIS, FRANCE, JAN 22,2022 - DEC 31,2022 2015</p>
          <p>- Hayes + Sharp: Old Decatur, CARNEGIE VISUAL ARTS CENTER DECATUR, ALABAMA, USA, JUN 23,2015 - AUG 01,2015</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Helenbeck Galerie / Jean Gismondi Galerie, Who's the king, Paris (France)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Helenbeck Galerie, Whole in the wall, New-York (USA)</p>
          <p>- Helenbeck Galerie, Il est interdit d'interdire, Nice (France)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Helenbeck Galerie / Jean Gismondi Galerie, "Whole in the wall", Paris (France)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- Space Time Light Gallery, Coming out of the can, New York</p>
          <p>- Monde de l ’art, 4 maîtres du Spray, Paris</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- Galerie Gleiditch 45, 8 Urban Artists, Berlin</p>
          <p>- Galerie Magda Danysz, Urban Art, Paris</p>
          <p>- Galerie Michel Vidal, Wanted, Paris</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Musée National des Monuments Français, Artistes américains et français 1981-1991, Paris</p>
          <p>- Galerie Black New Arts, Afro-américains et Europe, Paris</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- Museum of American Graffiti, New York</p>
          <p>- Colin Greco Gallery, Lo-Hi Sho, New York</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- Prego, KaDance, Milan 1987</p>
          <p></p>
          <p>- Franklin Furnace Gallery, Melee, New York</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Nada Gallery, Brute Force, New York</p>
          <p>- Cuando, Purgatory, New York</p>
          <p>- Basel Art Fair 16, Suisse</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Fun Gallery, Christmas Show, New York</p>
          <p>- Ground Zero Gallery, New York</p>
          <p>- Limbo Loundge, Sharp and Martin Wong, New York</p>
          <p>- Ursula Schurr Gallery, Stuttgart</p>
          <p>- Basel Art Fair 15, Suisse</p>
          <p>- Fay Gold Gallery, Atlanta</p>
          <p>- Gallozzi-Laplaca Gallery, New York Graffiti Writers 1972-1984, Abecedary, New York</p>
          <p>- First National Museum of Bullshit P.s.1, New York</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Fun Gallery, New York</p>
          <p>- Kamikaze Club, Urban Art, New York</p>
          <p>- East 7th street Gallery, Under Dog, New York</p>
          <p id="end">- Terminal Art Show, New York</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Sharp;